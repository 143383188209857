import { useState } from "react";
import toast from "react-hot-toast";
import supabaseClient from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
function MoreFoodInfo() {
    return (
        <div>
            HI
        </div>
    );
}


export default MoreFoodInfo;